export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [2,5];

export const dictionary = {
		"/(app)": [~7,[2]],
		"/(app)/cctv": [~8,[2]],
		"/(app)/docs": [9,[2]],
		"/(app)/event": [10,[2]],
		"/(app)/forgot-password": [~11,[2]],
		"/(app)/inventory/data": [~12,[2]],
		"/(app)/login": [~13,[2]],
		"/(app)/misc": [14,[3]],
		"/(app)/misc/barcode": [15,[3]],
		"/(app)/misc/client": [~16,[3]],
		"/(app)/misc/code": [~17,[3]],
		"/(app)/misc/components": [18,[3]],
		"/(app)/misc/default-data": [~19,[3]],
		"/(app)/misc/dialog": [20,[3]],
		"/(app)/misc/email": [~21,[3]],
		"/(app)/misc/fire-and-forget": [22,[3]],
		"/(app)/misc/offline-form": [~23,[3]],
		"/(app)/misc/print": [24,[3]],
		"/(app)/product/add": [~25,[2]],
		"/(app)/product/data": [~26,[2]],
		"/(app)/product/data/[brandName]/[model]": [~27,[2]],
		"/(app)/registrate": [~28,[2]],
		"/(app)/registrate/client": [~29,[2]],
		"/(app)/registrate/user": [~30,[2]],
		"/(app)/schedule": [~31,[2]],
		"/(app)/schedule/export": [~33,[2]],
		"/(app)/schedule/summary": [~34,[2]],
		"/(app)/schedule/[state]": [~32,[2]],
		"/(app)/sessionerror": [~35,[2]],
		"/(app)/store/offline": [36,[2,4,5]],
		"/(app)/store/offline/transfer": [37,[2,4,5]],
		"/(app)/store/offline/transfer/acquisition": [~38,[2,4,5]],
		"/(app)/store/offline/transfer/deliver": [~39,[2,4,5]],
		"/(app)/store/online": [40,[2,4,6]],
		"/(app)/subroot": [41,[2]],
		"/(app)/user": [~42,[2]],
		"/(app)/user/accept": [~44,[2]],
		"/(app)/user/[name]": [~43,[2]],
		"/(app)/warehouse": [45,[2]],
		"/(app)/warehouse/data": [~46,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';